import React, { FunctionComponent } from "react";
import Image from "next/legacy/image";

import spp from "../public/logos/sweetpeas.png";
import basecorp from "../public/logos/sponsor-basecorp.png";
import anchor from "../public/images/anchor.png";
import orb from "../public/logos/orb-clean.png";
import gobake from "../public/logos/go-bake.png";

const Thanks: FunctionComponent<{ title?: string }> = ({
  title = "Made possible by our generous sponsors",
}) => {
  return (
    <div className="my-4 rounded-xl bg-white/25 md:my-12">
      <div className="px-4 py-8 sm:px-8 lg:px-12">
        <div className="flex flex-col gap-8 items-center">
          <div className="w-full">
            <h2 className="mx-auto max-w-md text-xl font-extrabold text-center align-baseline text-brand-navy lg:max-w-xl">
              {title}
            </h2>
          </div>
          <div className="flex flex-wrap gap-y-8 justify-center items-center py-4 w-full sm:py-0">
            <div className="px-8 w-1/2 md:w-1/5">
              <a href="https://www.sweetpeaparties.co.nz/">
                <Image src={spp} alt="Sweetpea Parties" layout="responsive" />
              </a>
            </div>
            <div className="px-8 w-1/2 md:w-1/5">
              <a href="https://www.anchordairy.com/">
                <Image src={anchor} alt="Anchor Dairy" layout="responsive" />
              </a>
            </div>
            <div className="order-last px-10 w-1/2 md:w-1/5 md:order-none">
              <a href="https://gobake.co.nz/">
                <Image
                  src={gobake}
                  alt="Go Bake"
                  layout="responsive"
                  className="object-fill"
                />
              </a>
            </div>
            <div className="px-8 w-1/2 md:w-1/5">
              <a href="https://orbpro.co.nz/">
                <Image src={orb} alt="Orb Property" layout="responsive" />
              </a>
            </div>
            <div className="px-8 w-1/2 md:w-1/5">
              <a href="https://basecorp.co.nz">
                <Image
                  src={basecorp}
                  alt="Basecorp Finance - Hamilton"
                  layout="responsive"
                  className="object-fill"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
