import React, { FunctionComponent, ReactNode } from "react";

const Heading2: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  return (
    <h2 className="text-sm font-semibold tracking-wider uppercase md:text-base text-brand-ranga">
      {children}
    </h2>
  );
};

const Heading3: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  return (
    <h3 className="text-3xl font-bold tracking-tight text-brand-navy sm:text-4xl">
      {children}
    </h3>
  );
};

export { Heading2, Heading3 };
